<template>
  <div>
    <div>
      <h3 class="white--text text-center mb-5">Sony E-Vehicle program</h3>
    </div>
    <v-form v-if="!validCredentials" v-model="valid" @submit.stop.prevent="onSubmit">
      <v-card class="elevation-12">
        <v-toolbar dark color="info">
          <v-toolbar-title>
            <span>Login</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <ApiError :errors="errors"></ApiError>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.username"
                  :rules="rules.username"
                  label="Username or Email"
                  name="email"
                  prepend-icon="mdi-account"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="form.password"
                  label="Password"
                  name="password"
                  hint="Passwords are case-sensitive"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" align="right">
                <router-link :to="{ name: 'forgot-password' }">Forgot Password</router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn block type="submit" color="primary" :loading="loading" :disabled="loading">Login</v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" align="center" v-if="selectedProgram && selectedProgram.programEmail">
                Help? <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
              </v-col>
              <v-col cols="6" align="center" v-if="selectedProgram && selectedProgram.programPhone">
                Call? {{ selectedProgram.programPhone }}
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>
    <v-card v-if="validCredentials && !eveParticipant" class="elevation-12">
      <v-toolbar dark color="info">
        <v-toolbar-title>
          <span> You aren't enrolled in any program. </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" @click="onClear()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-alert dense outlined type="error">
          If you think this is an error simply call {{ env_phone }}. Our customer care team is available between 8:30am
          and 5:00pm (ct) Monday through Friday to assist.
        </v-alert>
      </v-card-text>
    </v-card>
    <v-card v-if="eveParticipant" class="elevation-12">
      <v-toolbar dark color="info">
        <v-toolbar-title>
          <span>Redirecting to your program ...</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" @click="onClear()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        Redirecting to your program. If you are not redirected shortly, please cancel and retry login.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError },
  name: "Login",
  metaInfo: {
    title: "Login"
  },
  data() {
    return {
      errors: {},
      valid: false,
      loading: false,
      validCredentials: false,
      form: {},
      showPassword: false,
      rules: {
        username: [v => !!v || "Username or Email is required"]
      },
      eveParticipant: undefined,
      unauthorizedError: false
    };
  },
  methods: {
    onSelect(participant) {
      return this.$auth
        .storeSelectParticipant(participant)
        .then(() => {
          this.$router.push({ name: "dashboard" }).catch(error => {
            console.log(error);
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    onClear() {
      this.eveParticipant = undefined;
      this.form.password = "";
      this.validCredentials = false;
    },
    onSubmit() {
      // if invalid, prevent submission
      if (!this.valid) {
        return;
      }

      this.errors = {};

      this.loading = true;

      // clear existing errors
      this.$auth.logout();

      // submit login request
      this.$auth
        .login(this.form.username, this.form.password)
        .then(() => {
          // determine if there are multiple programs for this user
          return this.$auth.storeAvailableParticipants();
        })
        .then(() => {
          this.eveParticipant = this.getEveParticipant();
          this.validCredentials = true;
          if (this.eveParticipant) {
            return this.onSelect(this.eveParticipant);
          }
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          if (this.$api.isUnauthorized(e)) {
            this.errors = this.$api.createError("Invalid username or password");
          } else if (this.$api.isLocked(e)) {
            this.errors = this.$api.createError(this.$api.getLockedMessage(e));
          } else if (this.$api.isCredentialsExpired(e)) {
            this.errors = this.$api.createError("Your credentials have expired, redirecting ...");
            let key = this.$api.getCredentialsExpirationKey(e);
            return this.$router.push({ name: "credentials-expired", query: { key: key } });
          } else {
            this.errors = this.$api.getErrorsFromResponse(e);
          }
          this.onClear();
        });
    },
    getEveParticipant() {
      let EVE_KEY = process.env.VUE_APP_PROGRAM_EVE_KEY;
      let found = this.availableParticipants.findIndex(needle => needle.program.programKey == EVE_KEY);

      return this.availableParticipants[found];
    }
  },
  computed: {
    ...mapGetters(["availableParticipants", "selectedProgram"])
  }
};
</script>
